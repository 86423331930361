import React from "react";
import ReactDOM from "react-dom/client";

import App from "./App";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { ThemeProvider } from "@mui/material";
import { createTheme } from "./constants/theme";

// @ts-expect-errors
const root = ReactDOM.createRoot(document.getElementById("root"));

const theme = createTheme();
root.render(
  <React.StrictMode>
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </LocalizationProvider>
  </React.StrictMode>
);
