import { Box, FormHelperText, Typography } from '@mui/material';
import { FastField, FieldProps } from 'formik';
import * as React from 'react';

import QuillEditor from 'components/form/QuillEditor';

interface FormikQuillFieldProps {
  name: string;
  label?: string;
  style?: React.CSSProperties;
  noTopPadding?: boolean;
  disabled?: boolean;
}

export const FormikQuillField: React.FC<FormikQuillFieldProps> = props => (
  <FastField name={props.name}>
    {({ field, meta, form }: FieldProps<string>) => (
      <Box pt={props.noTopPadding ? 0 : 2}>
        {props.label && <Typography variant='body1'>{props.label}</Typography>}
        <QuillEditor
          value={typeof field.value === 'string' ? field.value : ''}
          onChange={field.onChange(field.name)}
          style={props.style ?? {}}
          onBlur={() => form.setFieldTouched(field.name, true)}
          readOnly={props.disabled}
          modules={{
            clipboard: {
              allowed: {
                tags: [
                  'a',
                  'b',
                  'strong',
                  'u',
                  'i',
                  'ul',
                  'ol',
                  's',
                  'li',
                  'span',
                  'br',
                  'p',
                  'h1',
                  'h2',
                  'h3'
                ],
                attributes: ['href', 'rel', 'target', 'class']
              },
              matchVisual: false
            }
          }}
        />
        {meta.touched && meta.error && (
          <Box sx={{ mt: 3 }}>
            <FormHelperText error>{meta.error}</FormHelperText>
          </Box>
        )}
      </Box>
    )}
  </FastField>
);
