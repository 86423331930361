import { Backdrop, Box, CircularProgress, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import axios, { AxiosError } from "axios";
import {
  ExternalIncidentSettings,
  ExternalOrganization,
  ExternalSubmissionForm,
} from "components/ExternalSubmissionForm";
import React from "react";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

function App() {
  const classes = useStyles();

  const [organization, setOrganization] =
    React.useState<ExternalOrganization | null>(null);
  const [settings, setSettings] =
    React.useState<ExternalIncidentSettings | null>(null);
  const [submitted, setSubmitted] = React.useState(false);
  const [customDnsEnabled, setCustomDnsEnabled] = React.useState(true);

  const getOrganization = async () => {
    try {
      // this hits the lambda API with the updatable CORS implementation
      const result = await axios.post(
        `${process.env.REACT_APP_EXTERNAL_SUBMISSION_ENDPOINT}/dev/organization`,
        { customDomain: window.location.href }
      );

      setOrganization(result.data.organization);
      setSettings(result.data.settings);
    } catch (err) {
      if ((err as AxiosError)?.response?.status === 404) {
        setCustomDnsEnabled(false);
      }
    }
  };

  React.useEffect(() => {
    getOrganization();
  }, []);

  return (
    <div className="App">
      {!customDnsEnabled ? (
        <Typography align="center" color="textPrimary" variant="h4">
          This page does not exist
        </Typography>
      ) : (
        <>
          {organization && settings ? (
            <>
              {!submitted && (
                <ExternalSubmissionForm
                  organization={organization}
                  incidentSettings={settings}
                  setSubmitted={() => setSubmitted(true)}
                />
              )}
              {submitted && (
                <Box
                  display="flex"
                  sx={{
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100%",
                  }}
                >
                  <Typography variant="h5">
                    Thank you for submitting your report to {organization?.name}
                  </Typography>
                </Box>
              )}
            </>
          ) : (
            <Backdrop className={classes.backdrop} open={true}>
              <CircularProgress color="inherit" />
            </Backdrop>
          )}
        </>
      )}
    </div>
  );
}

export default App;
