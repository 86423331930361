import { MobileDatePicker, MobileDatePickerProps } from '@mui/lab';
import { FormControl, FormHelperText, TextField } from '@mui/material';
import { format } from 'date-fns';
import { FastField, FieldProps } from 'formik';
import * as React from 'react';

interface FormikDateFieldProps
  extends Omit<
    MobileDatePickerProps,
    'onChange' | 'renderInput' | 'value' | 'variant'
  > {
  name: string;
  required?: boolean;
}

export const FormikDateField: React.FC<FormikDateFieldProps> = props => (
  <FastField name={props.name}>
    {({ field, meta }: FieldProps<string>) => (
      <FormControl
        sx={{ width: '100%' }}
        error={Boolean(meta.touched && meta.error)}
      >
        <MobileDatePicker
          label={props.label}
          InputProps={props.InputProps}
          renderInput={inputProps => (
            <TextField
              variant='outlined'
              data-testid={props.name}
              {...inputProps}
            />
          )}
          inputFormat='yyyy-MM-dd'
          value={field.value ? new Date(field.value) : null}
          onChange={value => {
            if (value) {
              const dateStr = format(value, 'yyyy-MM-dd HH:mm:ss');
              field.onChange(field.name)(dateStr);
            }
          }}
          disableCloseOnSelect={false}
          okText=''
          cancelText=''
          disabled={props.disabled}
        />
        <FormHelperText>{meta.touched && meta.error}</FormHelperText>
      </FormControl>
    )}
  </FastField>
);
