import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  FormHelperText,
  Typography,
  useMediaQuery,
} from "@mui/material";
import axios from "axios";
import { Form, Formik, FormikErrors } from "formik";
import React from "react";
import * as Yup from "yup";
import { Buffer } from "buffer";

import { ExternalModalFields } from "./ExternalModalFields";

enum BreachIncidentType {
  Incident = "Incident",
  Breach = "Breach",
}

export interface ExternalIntakeFormValues {
  first_name: string;
  last_name: string;
  title: string;
  department: string;
  phone_number: string;
  email: string;
  incident_reported: Date;
  incident_discovered: Date;
  incident_occurred: Date;
  location_of_incident: string;
  incident_description: string;
  anonymous?: boolean;
}

export type ExternalOrganization = {
  name: string;
  logo: Buffer | null;
  organization_id: string;
};

export type ExternalIncidentSettings = {
  enable_anonymous_submission: boolean;
  enable_external_intake: boolean;
  incident_starting_number: number | null;
};

export const externalIntakeFormSchema: Yup.SchemaOf<ExternalIntakeFormValues> =
  Yup.object({
    first_name: Yup.string().required("First name is required"),
    last_name: Yup.string().required("Last name is required"),
    title: Yup.string().required("Title is required"),
    department: Yup.string().required("Department is required"),
    phone_number: Yup.string().required("Phone number is required"),
    email: Yup.string().email().required("Email is required"),
    incident_reported: Yup.date().required("Incident reported required"),
    incident_discovered: Yup.date().required("Incident discovered required"),
    incident_occurred: Yup.date().required("Incident occurred required"),
    location_of_incident: Yup.string().required(
      "Location of incident is required"
    ),
    incident_description: Yup.string().required(
      "Incident description is required"
    ),
    anonymous: Yup.boolean(),
  });

export const ExternalSubmissionForm: React.FC<{
  incidentSettings: ExternalIncidentSettings;
  organization: ExternalOrganization;
  setSubmitted: () => void;
}> = ({ incidentSettings, organization, setSubmitted }) => {
  const [formErrors, setFormErrors] = React.useState<boolean>(false);
  const [step, setStep] = React.useState(1);
  const [formIsSubmitting, setFormIsSubmitting] = React.useState(false);
  const [anonymous, setAnonymous] = React.useState(false);

  const desktop = useMediaQuery("(min-width:950px)");

  const initialValues: ExternalIntakeFormValues = {
    first_name: "",
    last_name: "",
    title: "",
    department: "",
    phone_number: "",
    email: "",
    incident_reported: new Date(),
    incident_discovered: new Date(),
    incident_occurred: new Date(),
    location_of_incident: "",
    incident_description: "",
    anonymous: false,
  };

  const handleAnonymous = (
    setValues: (
      values: React.SetStateAction<ExternalIntakeFormValues>,
      shouldValidate?: boolean | undefined
    ) => void,
    values: ExternalIntakeFormValues
  ) => {
    if (!anonymous) {
      setAnonymous(true);
      setValues({
        first_name: "N/A",
        last_name: "N/A",
        title: "N/A",
        department: "N/A",
        phone_number: "N/A",
        email: "NA@na.na",
        incident_reported: values.incident_reported,
        incident_discovered: values.incident_discovered,
        incident_occurred: values.incident_occurred,
        location_of_incident: values.location_of_incident,
        incident_description: values.incident_description,
        anonymous: true,
      });
    } else {
      setAnonymous(false);
      setValues({
        first_name: "",
        last_name: "",
        title: "",
        department: "",
        phone_number: "",
        email: "",
        incident_reported: values.incident_reported,
        incident_discovered: values.incident_discovered,
        incident_occurred: values.incident_occurred,
        location_of_incident: values.location_of_incident,
        incident_description: values.incident_description,
        anonymous: false,
      });
    }
  };

  const handleNextStep = () => {
    setStep(step + 1);
  };

  const handlePrevStep = () => {
    setStep(step - 1);
  };

  const handleSubmit = async (values: ExternalIntakeFormValues) => {
    setFormIsSubmitting(true);
    try {
      await axios.post(
        `${process.env.REACT_APP_EXTERNAL_SUBMISSION_ENDPOINT}/dev/incident`,
        {
          customDomain: window.location.href,
          values: {
            breach_incident_type: BreachIncidentType.Incident,
            reporter_first_name: values.first_name,
            reporter_last_name: values.last_name,
            reporter_title: values.title,
            reporter_department: values.department,
            reporter_phone: values.phone_number,
            reporter_email: values.email,
            reported_timestamp: values.incident_reported,
            discovered_timestamp: values.incident_discovered,
            occurred_timestamp: values.incident_occurred,
            location: values.location_of_incident,
            description: values.incident_description,
            anonymous,
          },
        }
      );
      setSubmitted();
    } catch (error: unknown) {
      console.log(error);
    }
    // try {
    //   const response = await externalCreateIncident(
    //     { ...values },
    //     BreachIncidentType.Incident,
    //     organization.organization_id
    //   );
    //   if (!response.error) {
    //     setSubmitted();
    //     enqueueSnackbar("Incident submitted", {
    //       variant: "success",
    //     });
    //   }
    // } catch (err: unknown) {
    //   enqueueSnackbar((err as AxiosError<ApiError>).message, {
    //     variant: "error",
    //   });
    // } finally {
    //   setFormIsSubmitting(false);
    // }
  };

  const validateFirstStep = (
    values: ExternalIntakeFormValues,
    errors: FormikErrors<ExternalIntakeFormValues>
  ) =>
    !values.first_name ||
    Boolean(errors.first_name) ||
    !values.last_name ||
    Boolean(errors.last_name) ||
    !values.email ||
    Boolean(errors.email);

  const validateSubmit = (
    values: ExternalIntakeFormValues,
    errors: FormikErrors<ExternalIntakeFormValues>
  ) => {
    setFormErrors(Object.keys(errors).length !== 0);
    return (
      !values.first_name ||
      Boolean(errors.first_name) ||
      !values.last_name ||
      Boolean(errors.last_name) ||
      !values.title ||
      Boolean(errors.title) ||
      !values.department ||
      Boolean(errors.department) ||
      !values.phone_number ||
      Boolean(errors.phone_number) ||
      !values.email ||
      Boolean(errors.email) ||
      Boolean(errors.incident_reported) ||
      Boolean(errors.incident_discovered) ||
      Boolean(errors.incident_occurred) ||
      !values.location_of_incident ||
      Boolean(errors.location_of_incident) ||
      !values.incident_description ||
      Boolean(errors.incident_description)
    );
  };

  const isNextButtonDisabled = (
    isSubmitting: boolean,
    values: ExternalIntakeFormValues,
    errors: FormikErrors<ExternalIntakeFormValues>
  ) => isSubmitting || (step === 1 && validateFirstStep(values, errors));

  return (
    <Card sx={{ padding: desktop ? 2 : 0 }}>
      <CardHeader
        sx={{ padding: 2 }}
        title={
          <Box>
            {organization.logo && (
              <img
                src={`data:image/gif;base64,${Buffer.from(
                  organization.logo
                ).toString("base64")}`}
                alt="company_logo"
                width="200px"
                height="200px"
              />
            )}
            <Typography variant="h4">
              {organization.name}&apos;s Intake Form
            </Typography>
          </Box>
        }
      />
      <CardContent sx={{ padding: 2 }}>
        <Typography sx={{ fontSize: "1.1em", paddingBottom: 2 }}>
          Step {step} of 2
        </Typography>
        {step === 1 && (
          <>
            <Typography sx={{ paddingBottom: 2 }}>
              Complete the following report if you are aware of or suspect a
              privacy-related incident. Note that you should complete this
              report even if you are unsure of whether the incident constitutes
              a privacy breach.
            </Typography>
            <Typography sx={{ paddingBottom: 2 }}>
              This is an administrative report only and should not contain any
              personal health information.
            </Typography>
          </>
        )}

        <Divider />
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={externalIntakeFormSchema}
        >
          {({ values, errors, setValues }) => (
            <Form>
              <ExternalModalFields step={step} anonymous={anonymous} />
              {incidentSettings.enable_anonymous_submission && step === 1 && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignContent: "center",
                  }}
                >
                  <Checkbox
                    checked={anonymous}
                    onChange={() => handleAnonymous(setValues, values)}
                  />
                  <Typography sx={{ padding: "9px", paddingLeft: "5px" }}>
                    I would like to report anonymously
                  </Typography>
                </Box>
              )}
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <FormHelperText error>
                  {formErrors &&
                    "There are some fields that have been filled incorrectly. Please go back and check for any errors."}
                </FormHelperText>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  paddingTop: 2,
                }}
              >
                {step === 2 && (
                  <Button
                    sx={{
                      borderRadius: 8,
                      mr: 2,
                      color: "#secondary.lightContrastText",
                    }}
                    disabled={formIsSubmitting}
                    variant="outlined"
                    onClick={() => handlePrevStep()}
                  >
                    Back
                  </Button>
                )}
                {step === 1 && (
                  <Button
                    disabled={isNextButtonDisabled(
                      formIsSubmitting,
                      values,
                      errors
                    )}
                    variant="contained"
                    color="secondary"
                    onClick={() => handleNextStep()}
                    sx={{ color: "#333333", borderRadius: 8 }}
                  >
                    Next
                  </Button>
                )}
                {step === 2 && (
                  <Button
                    disabled={
                      formIsSubmitting || validateSubmit(values, errors)
                    }
                    variant="contained"
                    color="secondary"
                    sx={{ color: "#333333", borderRadius: 8 }}
                    onClick={async () => handleSubmit(values)}
                  >
                    Submit
                  </Button>
                )}
              </Box>
            </Form>
          )}
        </Formik>
      </CardContent>
    </Card>
  );
};
