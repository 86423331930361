import type { Theme, ThemeOptions } from '@mui/material';
import { createTheme as createMuiTheme } from '@mui/material/styles';
import merge from 'lodash/merge';
import '@mui/styles';
import { lightShadows, darkShadows } from './shadows';

import { THEMES } from 'constants/themes';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

declare module '@mui/material/styles/createTypography' {
  interface Typography {
    label: React.CSSProperties;
  }

  interface TypographyOptions {
    label?: React.CSSProperties;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    label: true;
  }
}

const baseOptions: ThemeOptions = {
  direction: 'ltr',
  components: {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          label: 'p'
        }
      }
    },
    MuiAvatar: {
      styleOverrides: {
        fallback: {
          height: '75%',
          width: '75%'
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none'
        }
      },
      defaultProps: {
        variant: 'contained'
      }
    },
    MuiCardHeader: {
      defaultProps: {
        titleTypographyProps: {
          variant: 'h6'
        }
      }
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          display: 'flex',
          flexDirection: 'column',
          flex: '1 1 0'
        }
      }
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          borderRadius: 3,
          overflow: 'hidden'
        }
      }
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: 'auto',
          marginRight: '16px'
        }
      }
    },
    MuiTableBody: {
      styleOverrides: {
        root: {
          fontWeight: '400'
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          marginBottom: 1
        }
      }
    },
    MuiSkeleton: {
      defaultProps: {
        variant: 'rectangular'
      },
      styleOverrides: {
        root: {
          transform: 'none'
        }
      }
    },
    MuiDrawer: {
      defaultProps: {
        anchor: 'right'
      },
      styleOverrides: {
        root: {
          zIndex: 1300
        },
        paper: {
          maxWidth: '335px'
        }
      }
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          width: '100%'
        }
      }
    }
  },
  typography: {
    button: {
      fontWeight: 600
    },
    fontFamily:
      '"Rubik", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
    body1: {
      fontWeight: 300,
      fontSize: '1rem'
    },
    body2: {
      fontWeight: 300,
      fontSize: '0.875rem'
    },
    h1: {
      fontWeight: 400,
      fontSize: '3.5rem'
    },
    h2: {
      fontWeight: 400,
      fontSize: '3rem'
    },
    h3: {
      fontWeight: 400,
      fontSize: '2.25rem'
    },
    h4: {
      fontWeight: 400,
      fontSize: '2rem'
    },
    h5: {
      fontWeight: 500,
      fontSize: '1.25rem'
    },
    h6: {
      fontWeight: 500,
      fontSize: '1.125rem'
    },
    overline: {
      fontWeight: 500,
      fontSize: '0.9rem'
    },
    label: {
      fontWeight: 400,
      fontSize: '0.9rem',
      lineHeight: '1.2',
      marginTop: 16,
      marginBottom: '8px !important'
    }
  },
  shape: {
    borderRadius: 4
  }
};

const themesOptions = {
  [THEMES.LIGHT]: {
    components: {
      MuiInputLabel: {
        styleOverrides: {
          root: {
            backgroundColor: '#FFFFFF'
          }
        }
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            fontWeight: '400',
            fontSize: '0.8rem',
            lineHeight: 1
          }
        }
      },
      MUIDataTableHeadCell: {
        styleOverrides: {
          data: {
            fontWeight: '500',
            fontSize: '0.8rem',
            lineHeight: 1
          }
        }
      },
      MUIDataTableBodyRow: {
        styleOverrides: {
          responsiveStacked: {
            '@media (max-width: 899.95px)': {
              borderTopWidth: 'thick',
              borderBottomWidth: 'thick'
            }
          }
        }
      },
      MuiInputBase: {
        styleOverrides: {
          input: {
            '&::placeholder': {
              opacity: 0.86,
              color: '#42526e'
            }
          }
        }
      }
    },
    palette: {
      action: {
        active: '#6b778c',
        main: '#72D5C9'
      },
      background: {
        default: '#f4f5f7',
        selected: '#FEF6F0',
        paper: '#ffffff'
      },
      error: {
        contrastText: '#ffffff',
        main: '#DC3545'
      },
      mode: 'light',
      primary: {
        contrastText: '#ffffff',
        main: '#2C4251'
      },
      secondary: {
        contrastText: '#FFFFFF',
        main: '#F58949',
        light: '#C7CED4',
        lightContrastText: '#333333'
      },
      success: {
        contrastText: '#ffffff',
        main: '#4caf50'
      },
      text: {
        primary: '#172b4d',
        secondary: '#6b778c'
      },
      warning: {
        contrastText: '#ffffff',
        main: '#ff9800',
        light: '#FFC539'
      },
      info: {
        contrastText: '#ffffff',
        main: '#07A0C3',
        dark: '#2C4251'
      }
    },
    shadows: lightShadows
  },
  [THEMES.DARK]: {
    components: {
      MuiInputLabel: {
        styleOverrides: {
          root: {
            backgroundColor: '#1D2B35',
            backgroundImage:
              'linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))'
          }
        }
      },
      MUIDataTableBodyRow: {
        styleOverrides: {
          responsiveStacked: {
            '@media (max-width: 899.95px)': {
              borderTopColor: 'rgba(0,0,0,0.35)',
              borderBottomColor: 'rgba(0,0,0,0.35)',
              borderTopWidth: 'thick',
              borderBottomWidth: 'thick'
            }
          }
        }
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            borderBottom: '1px solid rgba(145, 158, 171, 0.24)',
            fontWeight: '400',
            fontSize: '0.8rem',
            lineHeight: 1
          }
        }
      },
      MUIDataTableHeadCell: {
        styleOverrides: {
          data: {
            fontWeight: '500',
            fontSize: '0.8rem',
            lineHeight: 1
          }
        }
      },
      MuiTableHeadCell: {
        data: {
          fontWeight: '500',
          fontSize: '0.8rem',
          lineHeight: 1
        }
      },
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: 'none',
            borderColor: '#F58949',
            '&:hover': {
              borderColor: '#AB5F33'
            }
          }
        },
        variants: [
          {
            props: { color: 'primary', variant: 'contained' },
            style: {
              textTransform: 'none',
              backgroundColor: '#F58949',
              '&:hover': {
                backgroundColor: '#AB5F33'
              }
            }
          }
        ],
        defaultProps: {
          variant: 'contained'
        }
      }
    },
    palette: {
      background: {
        default: '#101B24',
        selected: '#101B24',
        paper: '#1D2B35'
      },
      // divider: 'rgba(145, 158, 171, 0.24)',
      error: {
        contrastText: '#ffffff',
        main: '#DC3545'
      },
      mode: 'dark',
      primary: {
        contrastText: '#ffffff',
        main: '#ffffff'
      },
      secondary: {
        contrastText: '#FFFFFF',
        main: '#F58949',
        light: '#C7CED4',
        lightContrastText: '#FFFFFF'
      },
      success: {
        contrastText: '#ffffff',
        main: '#4caf50'
      },
      action: {
        main: '#72D5C9'
      },
      text: {
        primary: '#ffffff',
        secondary: '#ffffff'
      },
      warning: {
        contrastText: '#ffffff',
        main: '#ff9800',
        light: '#FFC539'
      },
      info: {
        contrastText: '#ffffff',
        main: '#07A0C3',
        dark: '#2C4251'
      }
    },
    shadows: darkShadows
  }
};

export const createTheme = () => {
  const selectedTheme = THEMES.LIGHT;
  const themeOptions = themesOptions[selectedTheme];

  const theme = createMuiTheme(
    merge({}, baseOptions, themeOptions, {
      ...{
        shape: {
          borderRadius: 16
        }
      }
    })
  );

  return theme;
};
