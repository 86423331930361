import { CalendarToday } from '@mui/icons-material';
import { Grid, Typography, InputAdornment } from '@mui/material';
import React from 'react';
import { FormikDateField } from './form/FormikDateField';
import { FormikQuillField } from './form/FormikQuillField';
import { FormikTextField } from './form/FormikTextField';

enum FieldType {
  TextField = 'TextField',
  DateField = 'DateField',
  TextArea = 'TextArea'
}

interface FormField {
  name: string;
  label: string;
  fieldType: FieldType;
  width: number;
}

const yourInformationFields: FormField[] = [
  {
    name: 'first_name',
    label: 'First Name',
    fieldType: FieldType.TextField,
    width: 6
  },
  {
    name: 'last_name',
    label: 'Last Name',
    fieldType: FieldType.TextField,
    width: 6
  },
  {
    name: 'email',
    label: 'Email',
    fieldType: FieldType.TextField,
    width: 12
  }
];

const reporterFields: FormField[] = [
  {
    name: 'first_name',
    label: 'First Name',
    fieldType: FieldType.TextField,
    width: 6
  },
  {
    name: 'last_name',
    label: 'Last Name',
    fieldType: FieldType.TextField,
    width: 6
  },
  {
    name: 'title',
    label: 'Title',
    fieldType: FieldType.TextField,
    width: 6
  },
  {
    name: 'department',
    label: 'Department',
    fieldType: FieldType.TextField,
    width: 6
  },
  {
    name: 'phone_number',
    label: 'Phone Number',
    fieldType: FieldType.TextField,
    width: 6
  },
  {
    name: 'email',
    label: 'Email',
    fieldType: FieldType.TextField,
    width: 6
  }
];

const incidentFields: FormField[] = [
  {
    name: 'incident_discovered',
    label: 'Incident Discovered',
    fieldType: FieldType.DateField,
    width: 6
  },
  {
    name: 'incident_occurred',
    label: 'Incident Occurred',
    fieldType: FieldType.DateField,
    width: 6
  },
  {
    name: 'location_of_incident',
    label: 'Location of Incident',
    fieldType: FieldType.TextField,
    width: 12
  },
  {
    name: 'incident_description',
    label: 'Incident Description',
    fieldType: FieldType.TextArea,
    width: 12
  }
];

interface ExternalModalFieldsProps {
  step: number;
  anonymous: boolean;
}

export const ExternalModalFields: React.FC<ExternalModalFieldsProps> = ({
  step,
  anonymous
}) => {
  const handleDisabled = (field: FormField, path: number) => {
    if (
      path === 2 &&
      (field.name === 'first_name' ||
        field.name === 'last_name' ||
        field.name === 'email')
    ) {
      return true;
    }
    return false;
  };
  return (
    <Grid container spacing={2}>
      {step === 1 && (
        <Grid item xs={12}>
          <Typography variant='h5' sx={{ marginY: 1.5 }}>
            Your Information
          </Typography>
        </Grid>
      )}
      {step === 1 &&
        yourInformationFields.map(field => (
          <Grid item md={field.width} xs={12} key={field.name}>
            <FormikTextField
              name={field.name}
              label={field.label}
              sx={{
                '& fieldset': {
                  borderRadius: '15px'
                }
              }}
              disabled={anonymous || handleDisabled(field, step)}
            />
          </Grid>
        ))}
      {step === 2 && (
        <Grid item xs={12}>
          <Typography variant='h5' sx={{ marginY: 1.5 }}>
            Reporter Information
          </Typography>
        </Grid>
      )}

      {step === 2 &&
        reporterFields.map(field => (
          <Grid item md={field.width} xs={12} key={field.name}>
            <FormikTextField
              name={field.name}
              label={field.label}
              sx={{
                '& fieldset': {
                  borderRadius: '15px'
                }
              }}
              disabled={anonymous || handleDisabled(field, step)}
            />
          </Grid>
        ))}

      {step === 2 && (
        <Grid item xs={12}>
          <Typography variant='h5' sx={{ marginY: 1.5 }}>
            Incident Description
          </Typography>
        </Grid>
      )}
      {step === 2 &&
        incidentFields.map(field => (
          <React.Fragment key={field.name}>
            {field.fieldType === FieldType.TextField && (
              <Grid item md={field.width} xs={12}>
                <FormikTextField
                  name={field.name}
                  label={field.label}
                  sx={{
                    '& fieldset': {
                      borderRadius: '15px'
                    }
                  }}
                />
              </Grid>
            )}
            {field.fieldType === FieldType.TextArea && (
              <Grid item md={field.width} xs={12}>
                <FormikQuillField
                  name={field.name}
                  label={field.label}
                  style={{ minHeight: '200px' }}
                  noTopPadding
                />
              </Grid>
            )}
            {field.fieldType === FieldType.DateField && (
              <Grid
                item
                md={field.width}
                xs={12}
                key={field.name}
                sx={{
                  '& fieldset': {
                    borderRadius: '15px'
                  }
                }}
              >
                <FormikDateField
                  name={field.name}
                  label={field.label}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <CalendarToday />
                      </InputAdornment>
                    )
                  }}
                />
              </Grid>
            )}
          </React.Fragment>
        ))}
    </Grid>
  );
};
