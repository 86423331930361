import { experimentalStyled } from "@mui/material/styles";

import "react-quill/dist/quill.snow.css";
import ReactQuill from "react-quill";

// eslint-disable-next-line @typescript-eslint/no-require-imports, @typescript-eslint/no-unused-expressions
typeof window === "object" ? require("quill-paste-smart") : () => false;

const QuillEditor = experimentalStyled(ReactQuill)(() => ({
  border: 1,
  borderColor: "rgba(145, 158, 171, 0.24)",
  borderRadius: "16px",
  borderStyle: "solid",
  display: "flex",
  flexDirection: "column",
  "& .ql-snow.ql-toolbar": {
    "&:first-child": {
      display: "none",
    },
    borderColor: "rgba(145, 158, 171, 0.24)",
    borderLeft: "none",
    borderRight: "none",
    borderTop: "none",
    "& .ql-picker-label:hover": {
      color: "#2C4251",
    },
    "& .ql-picker-label.ql-active": {
      color: "#2C4251",
    },
    "& .ql-picker-item:hover": {
      color: "#2C4251",
    },
    "& .ql-picker-item.ql-selected": {
      color: "#2C4251",
    },
    "& button:hover": {
      color: "#2C4251",
      "& .ql-stroke": {
        stroke: "#2C4251",
      },
    },
    "& button:focus": {
      color: "#2C4251",
      "& .ql-stroke": {
        stroke: "#2C4251",
      },
    },
    "& button.ql-active": {
      "& .ql-stroke": {
        stroke: "#2C4251",
      },
    },
    "& .ql-stroke": {
      stroke: "#000000",
    },
    "& .ql-picker": {
      color: "#000000",
    },
    "& .ql-picker-options": {
      backgroundColor: "#333333",
      border: "none",
      borderRadius: "16px",
      boxShadow: "0 0 1px 0 rgba(0,0,0,0.31), 0 9px 18px -8px rgba(0,0,0,0.25)",
      padding: "16px",
    },
  },
  "& .ql-snow.ql-container": {
    borderBottom: "none",
    borderColor: "rgba(145, 158, 171, 0.24)",
    borderLeft: "none",
    borderRight: "none",
    flexGrow: 1,
    "& .ql-editor": {
      color: "#000000",
      fontFamily: "",
      fontSize: "16px",
      padding: "16px",
      "&.ql-blank::before": {
        color: "red",
        fontStyle: "normal",
        left: "16px",
      },
    },
  },
}));

export default QuillEditor;
