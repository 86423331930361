import {
  OutlinedTextFieldProps,
  SxProps,
  TextField,
  Theme
} from '@mui/material';
import { FieldProps, FastField } from 'formik';
import * as React from 'react';

interface FormikTextFieldProps extends Omit<OutlinedTextFieldProps, 'variant'> {
  name: string;
  fast?: boolean;
  sx?: SxProps<Theme> | undefined;
  regex?: RegExp;
}

export const FormikTextField: React.FC<FormikTextFieldProps> = props => (
  <FastField name={props.name}>
    {({ field, meta }: FieldProps<string>) => (
      <TextField
        data-testid={props.name}
        error={Boolean(meta.touched && meta.error)}
        helperText={meta.touched && meta.error}
        variant='outlined'
        fullWidth={props.fullWidth ?? true}
        {...field}
        {...props}
        onChange={e => {
          if (props.regex) {
            if (props.regex.test(e.target.value)) {
              field.onChange(e);
            }
          } else {
            field.onChange(e);
          }
        }}
      />
    )}
  </FastField>
);
